import { localizationService } from '@shared-services/localization-service';
import {
    ProductPurchaseOptionType,
    ProductType,
    ProductViewType,
} from './types';
import { logger } from '@shared-services/log-service';
import { PRODUCT_ONE_TIME_PAYMENT_PLAN_ID } from '../consts';
import { SNIPCART_EVENTS } from 'client/widget-components/ecom/useSnipcartApi';

export function encodeUniqueKey(variation: { [key: string]: string } | string) {
    try {
        const txtVariation =
            typeof variation === 'string'
                ? variation
                : JSON.stringify(variation);
        if (window.TextEncoder) {
            const encodedArray = new TextEncoder().encode(txtVariation);
            const encodedVariation = String.fromCharCode(...encodedArray);
            return btoa(encodedVariation);
        }
        return btoa(txtVariation);
    } catch (err) {
        logger.warn({
            err,
            variation,
            tags: ['encodeUniqueKey'],
        });
        return '{}';
    }
}

export function decodeUniqueKey(base64Key: string) {
    try {
        return JSON.parse(
            new TextDecoder().decode(
                Uint8Array.from(atob(base64Key), (m) => m.charCodeAt(0))
            ) || '{}'
        );
    } catch (err) {
        return {};
    }
}

export function doOnSnipcartReady(callback: () => void) {
    window.document.addEventListener(SNIPCART_EVENTS.SNIPCART_READY, callback);
}

export function createProductPaymentPlanOptionList(product: ProductType) {
    const {
        purchase_options: purchaseOptions = [],
        payment_plans: paymentPlans = [],
    } = product;

    switch (true) {
        case purchaseOptions.length === 2:
            return [
                {
                    id: PRODUCT_ONE_TIME_PAYMENT_PLAN_ID,
                    name: localizationService.str(
                        'ui.runtimessr.priceOptions.option.oneTimeOnly'
                    ),
                    discount_percentage: 0,
                    plan_price: product.price,
                    plan_displayed_price: product.displayed_price,
                },
                ...paymentPlans,
            ];
        case purchaseOptions.includes(ProductPurchaseOptionType.SUBSCRIPTION):
            return paymentPlans;
        default:
            return [];
    }
}

export function mapProductViewToProduct(
    productView: ProductViewType
): ProductType {
    return {
        ...(productView || {}),
        options: (productView?.options || []).reduce(
            (acc, option) => ({
                ...acc,
                [option.name]: option.choices.split(',').map((choice) => ({
                    label: choice,
                    value: choice,
                })),
            }),
            {}
        ),
    };
}
